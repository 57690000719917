@import "../settings";

.product-list-item {
  border-bottom: 1px solid $medium-gray;
  padding-bottom: .5rem;
  margin-bottom: 1rem;

  &__header {
    color: $medium-gray;
    font-size: 1.4rem;

    &--sub {
      color: $primary-color;
      text-transform: none;
    }
  }

  &__icons {
    img {
      max-width: 80px;
      margin-right: .5rem;
      margin-bottom: .5rem;
    }
    @include breakpoint(medium down) {
      img {
        max-width: 100px;
      }
    }
  }
}
