@import "../settings";

.ce-uploads {


  &__item {
    padding-top: 4px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //margin-bottom: 1rem;
  }

  &__filename {

  }

  &__description {
    font-size: $small-font-size;
    margin-top: -6px;
    margin-bottom: -12px;
    font-weight: 600;
  }

  .icon-link {
    //margin-top: auto;
    padding: 0 2rem .5rem 2rem;
    background-position-y: 4px;
  }

  .cell {
    margin-bottom: .5rem;
  }
}
