.c-browser-detection {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: -3.2rem;
  z-index: 999999;

  border-top: none;
  font-size: 0.9rem;
  //border-radius: 0 0 0.4rem 0.4rem;

  text-align: center;
  transition: top 0.5s ease-in;

  &:hover {
    top: 0rem;
    .c-browser-detection--copytext {
      box-shadow: -3px 0px 10px -5px rgba(10,10,10,0.7);
    }
  }

  &--copytext {
    padding: 0.4rem;
    color: white;
    background-color: get-color('secondary');
    transition: box-shadow 0.5s ease-in;
  }

  &--warning {
    display: inline-block;
    padding: 0.4rem;
    color: white;
    background-color: get-color('secondary');
    box-shadow: -3px 5px 10px -5px rgba(10,10,10,0.7);
    cursor: pointer;
  }

  &--symbol {
    margin-right: 0.4rem;
  }

  & p {
    margin-bottom: 0;
  }
}
