@import "../settings";

.select {
    position: relative;
    display: inline-block;
    min-width: 60px;

    &--language {
        width: auto;
        color: $medium-gray;
        @include breakpoint(medium down) {
            display: inline-flex;
            vertical-align: middle;
        }
    }

    option {
        font-family: $secondary-font-family;
    }

    select {
        display: inline-block;
        width: 100%;
        cursor: pointer;
        outline: 0;
        border: 0;
        border-radius: 0;
        background: $white;
        color: $dark-gray;
        appearance: none;
        font-family: $secondary-font-family;

        &:hover,
        &:focus {
            color: $darker-gray;
            background: $light-gray;
        }

        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

}

.select__arrow {
    mask-image: url($arrow-right-white);
    background-color: $dark-gray;
    transform: rotate(90deg);
    fill: green;

    position: absolute;
    top: 12px;
    right: 10px;
    width: 12px;
    height: 12px;
    pointer-events: none;

    .select select:hover ~ &,
    .select select:focus ~ & {
        background-color: $darker-gray;
    }

    .select select:disabled ~ & {
        background-color: $medium-gray;
    }

}
