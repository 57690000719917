@import "../settings";

.menu-mega {
    z-index: 120;

    &__secon-level {
        background-color: $light-gray;
        padding-left: 0.5rem;
    }
    a.menu-mega__second-level-item {
        &--active {
            color: $primary-color;
        }
    }

    &__image {
        float: left;
        margin-right: 24px;
        max-width: 400px;
    }

    p {
        color: $darker-gray;
        font-size: 20px;
    }

    &__third-level {
        padding-bottom: 1rem;
        line-height: 1rem;
        font-size: 1rem;
        font-weight: lighter;

        li {
            margin-top: 8px;
        }

        &:not(:last-of-type) {
          border-bottom: 1px solid $dark-gray;
        }
        a.menu-mega__third-level-item {
            font-family: $tertiary-font-family;

            &--active {
                color: $primary-color;
            }
        }
    }

    &__dropdown {
        @extend .dropdown-pane;
        left: 0 !important;
        right: 0 !important;
        width: auto !important;
        background-color: rgba( $dropdown-bg, 1 );
        border: none !important;

        .contact-data {
            color: $darker-gray;
            .contact-data__opening-hours, a {
                color: $darker-gray;
            }
        }

        &.is-open {
            visibility: visible;
            margin-top: 24px;
        }

        ul {
            list-style-type: none;
            margin-left: 0;
        }

        a {
            color: $darker-gray;
            &:hover,
                &:active,
                &:focus {
                color: $primary-color;
                outline: none;
            }
        }

        .lighten {
            color: rgba( $text-color, .5 );
            border-color: rgba( $text-color, .5 );
            outline: none;
            &:hover {
                color: rgba( $text-color, 1 );
                border-color: rgba( $text-color, 1 );
            }
            &:hover,
                &:active,
                &:focus {
                outline: none;
            }
        }
    }
}

.menue-img {
    float: left;
    margin-right: 24px;
}

.topmargin-contact-data {
    margin-top: 92px;
}

.dark-line-menue {
    border-top: 1px solid $darker-gray;
}



