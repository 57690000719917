@import "../settings";

.contact-data {
  //color: $secondary-font-color;

  path {
   // fill: $secondary-font-color !important;
  }

  &--mega-menu {
    margin-top: -1.5rem;
  }

  &--light {
    color: $white;
    .icon-contact--mail {
      background-color: $white;
    }
  }

  &__opening-hours {
    margin-top: 1rem;
  }
}
