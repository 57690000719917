@import "../settings";

.footer {
    opacity: 0;
    padding-top: 1.2rem;
    margin-top: 3rem;
    padding-bottom: 2rem;
    background-color: get-color(quartiary);
    color: $white;
    a {
        color: $white;
    }
    ul {
        list-style: none;
        margin-left: 0;
        margin-bottom: 0;
    }
    .text-small {
        line-height: 28px;
    }

    .fa-linkedin {
        &:hover {
            color: #2867B2;
        }
    }
}

.footer a:hover {
    color: $primary-color;
}

.footer p {
    @include breakpoint (medium down) {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}


