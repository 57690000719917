@import "../settings";

.breadcrumbs {
  margin-top: 2rem;
  margin-bottom: 2rem;

  li:not(:last-child)::after {
    margin: 0 0.2rem;
  }

  li {
    font-size: .8rem;
    text-transform: none;
    color: $body-font-color;
    a {
      font-family: $body-font-family;
      font-size: 0.8rem;
      text-transform: none;
      padding-left: 0;
    }
  }
}
