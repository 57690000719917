@import "../settings";

.button {
  text-transform: uppercase;
  font-family: $secondary-font-family;
  font-size: 22px;
  padding: .7rem 2.5rem;
  min-width: 300px;
  margin-bottom: 0;

  &--primary {
    background-color: $secondary-color;
    &:hover, &:active, &:focus {
      background-color: darken($secondary-color, 10%);
    }
  }

  &--secondary {
    background-color: $primary-color;
    &:hover, &:active, &:focus {
      background-color: darken($primary-color, 10%);
    }
  }
}
