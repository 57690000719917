@import "../settings";

.top-bar {
    display: grid;
    grid-template-areas:
            'logo right-top'
            'logo right-bottom';
    background-color: transparent;

    &__left {
       grid-area: logo;
    }

    &__right {
        grid-area: right-top;
        margin-left: auto;
        align-self: flex-start;
    }

    .logo-navigation {
        width: 150px;
    }

    .link-cloud {
        font-size: 18px;
        background: url("../img/cloud-icon.png") no-repeat;
        background-position: 0px 2px;
        float: left;
        margin-right: 32px;
        padding-left: 40px;
        padding-top: 6px;
        height: 40px;
        a {
            color: $secondary-color;
        }
    }
}


