@import "../settings";

.ce-download {
  display: flex;
  width: 100%;
  padding: .5rem 0;
  align-items: center;

  @include breakpoint(small down) {
    display: inline-block;

    .ce-download__title {
      font-size: 1.1rem;
    }

    .ce-download__language {
      font-size: 1.1rem;
    }
    .ce-download__icon {
      margin-top: 4px;
    }

    a {
      margin-right: 4px !important;
      margin-left: 4px !important;
    }
  }

  a {
    color: $medium-gray;
    margin-right: 2px;
    margin-left: 2px;
  }

  &__single-link {
    margin-right: 100px !important;
    margin-left: auto !important;
  }

  &__single-link-hover {
    color: $medium-gray;
    &:hover {
      color: get-color('primary') !important;
    }
  }

  &__title {
    font-size: 1.2rem;
    color: $medium-gray;
    font-family: $secondary-font-family;
  }

  &__language {
    color: $medium-gray;
    font-size: 1rem;
    width: 100px;
    float: left;
  }

  &__icon {
    margin-top: 2px;
    margin-left: auto;
    margin-right: .2rem;
    float: left;
  }
}
