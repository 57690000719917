@import '../settings';

.c-user-login {
    float: left;
    display: flex;
    justify-content: flex-end;
    height: 1.8rem;

    &:hover {
        cursor: pointer;
    }

    &__icon {
        display: flex;
        align-self: center;
        &--inverted {
            color: $white;
        }
    }
}
