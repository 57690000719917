@import "../settings";

form {
  .btn-group {
    text-align: center;
  }

  legend {
    @extend h3
  }

  .error {
    border-color: $alert-color;
  }

  span.error {
    color: $alert-color;
    margin-bottom: 1rem;
    display: block;
  }
}

.f-message-field {
  min-height: 10rem;
}
