@import "../settings";

#hamburger-icon {
  margin-left: 1rem;
  margin-top: 1rem;
  font-size: 2rem;
  &:hover {
    color: lighten($body-font-color, 20%);
  }
  color: $body-font-color;
  transition: color .5s;
  cursor: pointer;
}

.menu-mobile-container {
  height: 100%;

  > div {
    height: 100%;
  }
}

.menu-mobile {
  @extend .vertical, .menu, .drilldown;
  height: auto;

  li.js-drilldown-back {
    a {
      background: $white;
      text-transform: none;
    }
  }

  a {
   line-height: $header-lineheight;
  }

  .is-active > .menu-mobile__item {
    color: $white;
  }

  .is-active > .menu-mobile__second-level-item {
    color: $darker-gray;
  }

  li:not(.js-drilldown-back) {

    &:last-of-type {
      border-bottom: 1px solid;
    }
  }

  &__item-group {
    a {
      text-transform: uppercase;
    }
  }

  &__item {
    color: $white;
    &:hover {
      color: darken($white, 20%);
    }
    background-color: $menu-first-level-color !important;
    border-top: 1px solid $darker-gray;
  }

  &__second-level {
    @extend .menu, .vertical, .nested;
  }

  &__second-level-item {
    background-color: $menu-second-level-color !important;
    border-top: 1px solid $darker-gray;
    &:hover {
      color: darken($darker-gray, 20%);
    }
  }

  &__third-level {
    @extend .menu, .vertical, .nested;

    li:not(.menu-mobile__item-group){
      a {
        text-transform: none;
      }
    }
  }

  &__third-level-item {
    background: $menu-third-level-color !important;
    text-transform: uppercase;
    border-top: 1px solid $darker-gray;
    font-size: 1.1rem;
    padding-left: 2rem !important;
    &:hover {
      color: darken($darker-gray, 20%);
    }
  }

  .cloud-link {
    display: flex;
    a:before {
      content: '';
      background: url("../img/cloud-icon.png") no-repeat;
      width: 34px;
      height: 34px;
      display: inline-block;
      float: left;
      margin-right: .5rem;
    }

    a {
      display: flex;
      justify-content: end;
      align-items: center;
      color: $secondary-color;
    }
  }

  &__search {
    display: flex;
    padding: 1rem;
    align-items: center;
    div {
      width: 100%;
    }
    svg {
      font-size: 1.25rem;
      margin-right: .8rem;
      margin-left: .2rem;
    }
  }

  .is-drilldown-submenu-parent > a::after,
  .js-drilldown-back > a::before {
    border: none;
    background-image: url($img-arrow-white-bg);
    background-size: 30px;
    background-repeat: no-repeat;
    height: 30px;
    width: 30px;
  }


  .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 20px;
  }

  .js-drilldown-back > a::before {

    transform: rotate(180deg);
  }
}
