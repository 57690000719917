@import "../settings";

.contact-sticky {
  top: 25%;
  overflow: visible;
  visibility: visible !important;
  background-color: $dark-gray;
  width: auto;
  height: auto;
  transform: translateX(100%);
  padding-bottom: 2rem;

  a:focus {
    outline: none;
  }

  @include breakpoint(medium down) {
    display: none;
  }

  &.is-open {
    .fa-chevron-circle-up {
      transform: rotate(180deg);
    }

    box-shadow: -3px 3px 10px -5px rgba(10, 10, 10, 0.7) !important;
  }

  &__button {
    box-shadow: -3px -5px 10px -5px rgba(10, 10, 10, 0.7);
    text-align:center;
    margin-right: 1rem;
    background-color: $secondary-color;
    padding: .5rem;
    text-transform: uppercase;
    position: relative;
    top: 52px;
    right: 100px;
    width: 152px;
    height: 48px;
    z-index: 200;

    transform: rotate(270deg);
    color: white;

    &:hover {
      cursor: pointer;
    }

    svg {
      margin-left: .5rem;
    }
  }

  &__contact-data {
    min-width: 260px;
  }

  &__content {
    padding: 0 10rem 0 2rem;
    color: white;

    h1, h2, h3, a {
      color: white;
    }
  }
}
