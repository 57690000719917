.leaflet-pane {
  z-index: 6;
}
.leaflet-bottom {
  z-index: 20;
}
.leaflet-top {
  z-index: 9;
  .leaflet-control {
    z-index: 7;
  }
}

