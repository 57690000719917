.icon-contact {
  width: 18px;
  margin-right: 16px;

  &--mail {
    mask-image: url("../img/mail_solid.svg");
    background-color: $secondary-font-color;
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
  }
}

.icon-link {
  background-size: 1.5rem;
  background-position-y: 50%;
  padding: .5rem 2rem;
  background-repeat: no-repeat;
  font-size: 1.2rem;
  color: $medium-gray;
  display: block;
  font-family: $secondary-font-family;

  &--arrow {
    background-image: url("../img/xion-arrow.svg");
    color: $medium-gray;
  }

  &--download {
    background-image: url("../img/download.svg");
    color: $medium-gray;
  }
}
