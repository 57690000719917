.product-tile {
  padding-top: 1rem;
  border-bottom: 8px solid #737373;
  height: 100%;
  padding-bottom: 1rem;

  &__image {
    width: 100%;
    border: 1px solid $medium-gray;
    margin-bottom: 24px;
  }
}
