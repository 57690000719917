@import "../settings";

nav {
  a {
    outline: none;
    font-family: $body-font-family;
    font-weight: lighter;
    font-size: 1rem;
    color: $body-font-color;
    margin-bottom: 0;
    padding-left: 0.5rem;
    &:hover,
    &:active,
    &:focus {
      outline: none;
      color: $primary-color;
    }
  }
}
