@import "../settings";

/*
 * some overrides are necessary
 */

.n-submenu {
  &__header {
    background-color: $light-gray;
  }

  &__title {
    display: block;
    // lazy solution not to check for first subelement in navigationlist
    padding: 1rem 1rem 0.75rem 1rem;
    text-align: left;
    font-size: 0.9rem;
  }

  &__navigationlist {
    margin: 0;

    // overwrite for accordion.scss!
    & .is-accordion-submenu a {
      //padding-left: 0;
    }

    &--first {
      background-color: $light-gray;
    }

    &--second {
      /* necessary override */
      margin-left: 0 !important;
    }
  }

  &__link {
    color: white;

    &--first {
      margin-top: 0.25rem;
      background-color: $medium-gray;

      &-active {
        background-color: get-color('tertiary'); // gold
      }

      &:hover {
        color: white;
        background-color: get-color('tertiary');
      }
    }
    &--second {
      color: white;
      background-color: $medium-gray;
      font-size: 0.8rem;
      padding-left: 1rem;

      &-active {
        background-color: get-color('tertiary'); // gold
      }

      &:hover {
        color: white;
        background-color: get-color('tertiary'); // gold
      }
    }
  }
}



