@import '../settings';

.tx-indexedsearch-searchbox {
  &-button {
    @include button(false, get-color(primary), darken(get-color(primary), 15%), $white, solid);
  }
}

.tx-indexedsearch-browsebox {
  ul {
    list-style: none;
    font-size: $small-font-size;
    margin-left: 0;

    li {
      display: inline;
      margin-right: .5rem;
    }
  }
}