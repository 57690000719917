@import "../settings";

.cookie-consent-container {
  .consent-modal {
    border-radius: 0 !important;

    .label {
      background: none;

      input:checked~.checkmark,
      &.partially-checked .checkmark {
        background-color: $primary-color !important;
      }
    }

    .cookie-headline {
      @extend h2;
    }

    .option-wrapper {

      .option-headline {
        @extend h3;
      }
    }

    .buttons {
      .btn {
        border-radius: 0;

        &.btn--primary {
          @extend .button--secondary;
          border: none;
        }
      }
    }
  }
}

.cookie-consent-settings {
  .cookie-consent-open {
    line-height: 1.6;
    color: $white;
    &:hover {
      cursor: pointer;
      color: $primary-color;
    }
  }
}
