@import 'settings';
@import './node_modules/foundation-sites/scss/foundation';
@import './node_modules/motion-ui/src/motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
@include foundation-table;
// Basic components
//@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
//@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

@import "utilities";
@import "ce/uploads";
@import "ce/download";
@import "ce/form";
@import "ce/image";
@import "ce/indexed-search";
@import "ce/map";

@import "cce/accordion";
@import "components/back-to-top";
@import "cce/contact-sticky";
@import "cce/product-list-item";
@import "cce/product-tile";
@import "cce/request-box";
@import "cce/timeline";

@import "components/homepage-events";
@import "components/breadcrumbs";
@import "components/button";
@import "components/contact-data";
@import "components/contenttable";
@import "components/footer";
@import "components/header";
@import "components/icons";
@import "components/menu-main";
@import "components/menu-mega";
@import "components/menu-mobile";
@import "components/mobile-menu-off-canvas";
@import "components/nav";
@import "components/search-bar";
@import "components/select";
@import "components/support-overview";
@import "components/top-bar";
@import "components/typography";
@import "components/browser-detection";
@import "components/submenu";
@import "components/user-login";
@import "overrides/typo3-image-rendering";

@import "../../node_modules/bxslider/dist/jquery.bxslider.css";
@import "../../node_modules/leaflet/dist/leaflet.css";
//@import "overrides/accordion";
@import "sr-slider/bxslider";
@import "components/teaser";
@import "overrides/cookie-consent";
@import "overrides/card";
