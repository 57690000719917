@import "../settings";

.search-bar {
  float: left;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  &:hover {
    cursor: pointer;
  }

  &__icon {
    display: flex;
    align-self: center;
      &--inverted {
          color: $white;
      }
  }

  &__input {
    margin-bottom: 0;
    margin-right: .5rem;
    display: none;
    visibility: hidden;
    opacity: 0;
    width: 0;
    transition: visibility .4s, width .4s, opacity .4s;

    &--visible {
      visibility: visible;
      opacity: 1;
      width: 100%;
    }
  }
}
