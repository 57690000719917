@import '../settings';

h1, h2, h3, h4 {
    //letter-spacing: $header-letterspacing;
}

h1 {
    color: $primary-color;
    margin-bottom: 0.1rem;
}

h2 {
    color: $medium-gray;
    line-height: 38px;
    @include breakpoint(medium down) {
        line-height: 34px;
    }
}

h2 + p {
    padding-top: .5rem;
}

h1,h2,h3,h4 {
    &.green {
        color: $primary-color;
    }
    &.gray {
        color: $darker-gray;
    }
    &.medium-gray {
        color: $medium-gray
    }
    &.orange {
        color: $secondary-color;
    }
    &.mixed-case {
        text-transform: none;
    }
}

h3 {
    color: $primary-color;
    text-transform: uppercase;
    line-height: 30px;
}

.text-small {
    font-size: $small-font-size;
}

h4 {
    color: $darker-gray;
    font-size: 1.2rem;
    margin-top: 1.4rem;
    .slider {
        margin-top: 0px !important;
    }
}

a.disabled {
    pointer-events: none;
    cursor: default;
}

p {
    line-height: 1.4;
}

.icon-arrow-link {
    color: $medium-gray;
    font-family: 'Univers LT W01_47 Light1475998',Helvetica,Roboto,Arial,sans-serif;
    font-weight: $global-weight-bold;
    font-size: 1.2rem;
    padding-left: 38px;
    background: url("../img/arrow-right-bg.svg") no-repeat;
    background-size: 1.2rem;
    background-position-y: 50%;

    &--inverted {
        color: $white;
    }
}

.contact-data {
    font-size: 18px;
}

.product-benefits {
    p {
        font-size: 18px;
    }
    h3 {
        color: $primary-color;
    }
}

.breadcrumbs {
    font-size: 14px;
}

hr {
    border-top: 8px solid $dark-gray;
}
