@import "../settings";

// frontend layout = 200

.c-homepage-events-header {
    padding: 0.5rem;
    background-color: $light-gray;
    & h2 {
        color: $black;
        font-size: 1.1rem;
        height: 1.2rem;
        font-family: $body-font-family;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;
    }

    & > h2 + p {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

.c-homepage-events-list {
    padding: 0.5rem;
    color: $white;
    background-color: get-color('primary');
}

.c-homepage-events-item {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.c-homepage-events-date {
    font-weight: bold;
}
