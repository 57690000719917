.contenttable {
  &--borderless {
   width: auto;
    .text-small {
      margin-bottom: 0;
    }
    tbody {
      border: none;
      background-color: transparent;
      tr {
        background-color: transparent;
        td {
          padding: 0;

        }
      }
    }
  }
}
