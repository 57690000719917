@import "../settings";

#back-top {
  opacity: 0;
  position: fixed;
  bottom:20px;
  right: 2%;
  z-index: 100;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  font-size: 120%;
  padding: 6px 0 8px 0;
  @include breakpoint(medium down) {
    opacity: 1;
  }
}

#back-top .circle {
  background-color: $primary-color;
  padding: 0;
  border-radius: 50%;
  height: 44px;
  width: 44px;
}

.fa-chevron-up {
    margin-bottom: 2px;
    color: $white;
}


