@import "../settings";

.request-box {
  width: 100%;
  font-family: $secondary-font-family;
  border: 1px solid $medium-gray;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 8px;

  &__header {
    color: $medium-gray;
    font-size: 26px;
  }

  &__text {
    color: $medium-gray;
    font-size: 26px;
  }
}
