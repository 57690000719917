@import "../settings";

#mobileMenuOffCanvas {
    width: 100%;
    background-color: $body-background;
    visibility: hidden;
    &.is-open {
       visibility: visible;
    }
}

#mobile-menu-dirlldown {
    margin-top: 2.5rem;
}

