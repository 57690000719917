@import '../settings';

/* ------------- TIMELINE ------------- */
.timeline {
  margin-left: 2rem;
  display: none;

  @include breakpoint(medium) {
    justify-content: center;
    margin-left: 0;
  }

  &__section {
    position: relative;
    display: flex;
    flex-direction: column;

    &:nth-child(2n) .timeline__content--left {
      @include breakpoint(small down) {
        order: 2;
      }
    }

    @include breakpoint(medium) {
     flex-direction: row;
    }
  }

  &__content {
    width: auto;
    flex-grow: 0;
    display: flex;
    flex-flow: column-reverse;
    margin-right: 0;
    margin-left: 2rem;
    margin-bottom: 2rem;

    @include breakpoint(medium) {
      width: 100%;
      display: block;
      &--right {
        margin-left: 3rem;
        margin-right: 0;
      }

      &--left {
        margin-right: 3rem;
        margin-left: 0;
      }
    }
  }
}

.timeline__year {
  font-family: $tertiary-font-family;
  font-size: 1.25rem;
  position: absolute;
  background: $primary-color;
  text-align: center;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  @include breakpoint(small down) {
    transform: none;
  }
  width: 60px;
  height: 60px;
  margin-left: -30px;
  color: $white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include breakpoint(medium) {
    left: 50%;
  }
}

.timeline__container {
  width: 100%;
  position: relative;

  &:before {
    content: "";
    transform: translateX(-50%);
    background: $body-font-color;
    opacity: 0.6;
    height: 100%;
    width: 2px;
    top: 0;
    position: absolute;

    @include breakpoint(medium) {
      left: 50%;
    }
  }
}

/* ------------- EFFECTS ------------- */

.timeline__section {
  &::before {
    transition: background .5s ease-in-out;
  }

  &--in-view::before {
    background: $white;
    border: 2px solid get-color('primary');
  }

  div {
    visibility: hidden;
    @include breakpoint(small down) {
      visibility: visible;
      opacity: 1;
    }
    opacity: 0;
    transition: all .5s ease-in-out;
  }

  &--in-view div {
    transform: none !important;
    visibility: visible;
    opacity: 1;
  }
}
