@import "../settings";

.c-teaser-card {
    &--big {
        height: 460px;
        margin-top: 2rem;
    }

    &--small {
        height: 315px;
    }

    &--background- {
        background-color: get-color('secondary');

        &green {
            background-color: get-color('secondary');
        }

        &black {
            background-color: get-color('quartiary');
        }

        &gold {
            background-color: get-color('tertiary');
        }

        &red {
            background-color: get-color('primary'); // not yet used
        }
    }

    &__section {
        &--big {
            height: 5rem;
            transition: height 0.5s ease-in-out;
        }

        &--big:hover {
            height: 10rem;
        }
    }

    &__image {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        &--small {
            height: 150px;
            overflow: hidden;
        }

        &--big {
            height: 350px;
            overflow: hidden;

        }
    }

    &__imagefile {
        &--small {
            min-height: 150px;
        }
    }

    &__headline {
        color: $white;

        &--big {
            // no definition yet
        }

        &--small {
            font-size: 1.25rem;
        }
    }

    &__text {
        color: $white;

        &--small {
            // no definition yet
        }

        &--big {
            margin-top: 2rem;
        }
    }

}
