@import "../settings";

.support-overview {
  background-color: get-color(secondary);
  padding: 1rem;

  &__item {
    color: $white;

    h3 {
      color: $white;
    }

     p {
       font-size: 1rem;
       a {
         text-transform: none;
         background-color: get-color(secondary);
         color: $white;
         font-size: 1rem;
         padding: 0;
         display: inline-block;
       }
     }
  }
}