@import "../../../node_modules/foundation-sites/scss/foundation";
@import '../settings';
//@import '../mixins';

.c-accordion {
    @include accordion-container;

    $path-icon-plus: '../img/xion-arrow.svg';
    $path-icon-minus:  '../img/xion-arrow.svg';

    &__title {
        @include accordion-title;
        padding: 0;
        $accordion-background-color: $white !default;
        background-color: $accordion-background-color;
        font-size: $global-font-size;
        color: $primary-color;
        border: none !important;
        display: flex;

        &:before {
            content: none !important;
        }

        &:hover {
            background-color: darken($accordion-background-color, 15%);
            cursor: pointer;
        }
    }

    &__headlines {
        align-self: center;
    }

    &__icon {
        background-color: $primary-color;
        @include breakpoint(small down) {
            max-width: 2rem;
        }

        width: 3rem;
        padding: .7rem;
        margin-right: 1rem;
        display: flex;
        img {
            transition: transform .3s ease;
        }
    }

    &__item {
        @include accordion-item;
        border: solid 1px $body-font-color;
        margin-bottom: .5rem;

        &.is-active {
            .c-accordion__icon {
                img {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &__header {
        color: $primary-color;
        line-height: 1.2;
        margin-bottom: 0;
        text-transform: none;
        font-size: 26px;
        &--sub {
            line-height: 1.2;
            font-size: $small-font-size;
            color: $darker-gray;
            margin-bottom: 0;
            text-transform: uppercase;
            padding-top: 4px;
        }
        @include breakpoint(small down){
            font-size: 22px;
            margin-bottom: 4px;
        }
    }

    &__content {
        @include accordion-content;
        border: none;
        border-bottom: none !important;

        h4 {
            margin-top: 6px;
        }

        .ce-uploads__item, .ce-download {
            border-bottom: 1px solid $darker-gray;
        }
    }
}
