@import "../settings";

.bxslider {

    &__icon-link {
        float: left;
         a {
             color: $medium-gray;
             &:hover {
                 color: $primary-color;
             }
        }
        @include breakpoint(medium down) {
            float: none;
        }
    }

    @include breakpoint(medium down) {
        .icon-link {
            font-size: 1rem;
        }
        h4 {
            font-size: 1.2rem;
        }
    }

    &__text-container {
        position: absolute;
        width: 100%;
        left: 5rem;
        top: 10rem;
        max-width: 30rem;
        padding: 0 18px 12px 18px;
        background: rgba(255,255,255,.9);
        transition: all ease-in .5s;
        @include breakpoint(xlarge) {
            top: 17rem;
        }

        @include breakpoint(medium down) {
            display: block;
            position: relative;
            top: 0;
            left: 0;
        }
    }
}

.bx-wrapper {
    box-shadow: none;
    border: none;
    background: none;

    @include breakpoint(medium down) {
        .bx-controls-direction a {
            top: 30%;
        }
    }

    .bx-prev, .bx-next {
        opacity: .8;
        transition: opacity .5s;
        &:hover {
            opacity: 1;
        }
    }

    img {
        width: 100%;
    }

    .bx-controls-direction {
        a {
            z-index: 4;
        }
    }
}
