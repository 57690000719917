@import "../settings";

.ce-image {
  .ce-border img, .ce-border iframe {
    border: 1px solid $medium-gray;
  }
}

img {
  -webkit-transform: translateZ(0);
}
