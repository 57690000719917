@import "../settings";

.menu-main {
    @include show-for(large);
    grid-area: right-bottom;
    align-self: end;

    .menu-main__item {
        color: $white;

        &:hover {
            color: $black;
        }

        &--active {
            color: $primary-color;
            color: $black;
        }
    }
}
